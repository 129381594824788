import React from "react";
import { navigate, FormattedMessage } from "gatsby-plugin-intl";

import * as constants from "../app.constant";
import AppLayout from "../components/shared/AppLayout";
import pageNotFoundIcon from "../assets/images/page_not_found_icon.svg";

const PageNotFound = () => {

    const navigateBack = () => {
        navigate(constants.ROUTES.PLANS);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-error">

                <div className="page-not-found">
                    <div className="container">
                        <div className="page-not-found__header">
                            <h2 className="page-not-found__status">
                                <img className="page-not-found__status-icon img-fluid" src={pageNotFoundIcon} alt="" width="44" />
                                <span className="page-not-found__status-text text-bold">404 - Page not found</span>
                            </h2>
                            <p>
                                <FormattedMessage id="system.notFound.description" />
                            </p>
                        </div>

                        <p className="btn-container btn-container--center">
                            <button className="btn btn-lg btn-primary" type="button" onClick={navigateBack}>
                                <FormattedMessage id="system.notFound.cta" />
                            </button>
                        </p>
                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageNotFound;
